import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// COMPONENTS
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

// PAGES
import Home from './pages/Home/Home'
import Rules from './pages/Rules/Rules'

// STYLES
import useStyles from './appUseStyles'

function App() {
	const classes = useStyles()

	const pageList = [
		// HOME PAGE
		{
			pageComponent: <Home/>,
			exact: true,
			path: '/',
		},
		// PRIVACY POLICY PAGE
		{
			pageComponent: <Rules/>,
			exact: true,
			path: '/privacy-policy',
		},
		// TERMS OF SERVICE PAGE
		{
			pageComponent: <Rules/>,
			exact: true,
			path: '/terms-of-service',
		},
		// SUPPORT REGULATION PAGE
		{
			pageComponent: <Rules/>,
			exact: true,
			path: '/support-regulation',
		},
	]

	const pageListComponent = pageList.map((item, index) => (
		<Route
			key={index}
			exact={item['exact']}
			path={item['path']}
		>
			{item['pageComponent']}
		</Route>
	))

	return (
		<div>
			<Router>
				<Switch>
					<div className={classes['root']}>
						{/* HEADER COMPONENT */}
						<Header/>
						
						{/* PAGES */}
						{pageListComponent}

						{/* FOOTER COMPONENT */}
						<Footer/>
					</div>
				</Switch>
			</Router>
		</div>
	)
}

export default App
