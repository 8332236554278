import React from 'react'

// MATERIAL UI CORES
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const ScreenSizesContext = React.createContext()

const ScreenSizesContextProvider = (props) => {
    const theme = useTheme()
    const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'))
    const isSmOnly = useMediaQuery(theme.breakpoints.only('sm'))
    const isMdOnly = useMediaQuery(theme.breakpoints.only('md'))
    const isLgOnly = useMediaQuery(theme.breakpoints.only('lg'))
    const isXlOnly = useMediaQuery(theme.breakpoints.only('xl'))

    return (
        <ScreenSizesContext.Provider
            value={{
                isXsOnly, isSmOnly, isMdOnly, isLgOnly, isXlOnly
            }}
        >
            {props['children']}
        </ScreenSizesContext.Provider>
    )
}

export { ScreenSizesContextProvider, ScreenSizesContext }