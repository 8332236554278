import React from 'react'

// MATERIAL UI CORES
import { useScrollTrigger } from '@material-ui/core'

const defaultBoxShadow = '0px 8px 40px rgba(15, 160, 217, 0.05)'

const ScrollEffectHeader = (props) => {
    const {
        threshold,
        children,
    } = {
        threshold: 0,
        boxShadow: defaultBoxShadow,
        ...props
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: threshold,
        target: props['window'] ? window() : undefined
    })

    return React.cloneElement(children, {
        style: {
            boxShadow: trigger ? defaultBoxShadow : 'none',
        },
    })
}

export default ScrollEffectHeader