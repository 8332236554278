import React, { useContext } from 'react'

// CONTEXTS
import { ScreenSizesContext } from '../../../contexts/ScreenSizesContext/ScreenSizesContext'

// IMAGES
import PictureBanner from '../../../images/pictures/home_introduction_banner.png'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

// STYLES
import useStyles from './introductionUseStyles'

function Introduction() {
    const classes = useStyles()

    const { isLgOnly, isXlOnly } = useContext(ScreenSizesContext)

    const title = 'Kelola armada, lengkap di satu tempat'
    const caption = 'Kinerja armada, efisiensi bahan bakar, keselamatan pengemudi, penjadwalan pemeliharaan, dan hubungkan ke Transportation Management System (TMS)'

    const leftContent =
    <div className={classes['leftContent']}>
        {/* TITLE */}
        <Typography 
            variant='h3'
            className={classes['titleText']}
        >
            {title}
        </Typography>

        {/* CAPTION */}
        <Typography 
            variant='h5'
            className={classes['captionText']}
        >
            {caption}
        </Typography>

        {/* CONTACT US */}
        <Button className={classes['contactUsButton']}>
            Hubungi Kami
        </Button>
    </div>

    const rightContent =
    <div className={classes['rightContent']}>
        <img 
            src={PictureBanner} 
            alt='' 
            className={classes['banner']}
        />
    </div>

    return (
        <div 
            className={
                (isLgOnly || isXlOnly) ?
                `${classes['root']} ${classes['negativeMarginTop']}` :
                classes['root']
            }
        >
            <Grid container>
                {/* LEFT CONTENT */}
                <Grid 
                    item 
                    xs={12} sm={12} md={12} lg={5} xl={5}
                >
                    {leftContent}
                </Grid>

                {/* RIGHT CONTENT */}
                <Grid 
                    item 
                    xs={12} sm={12} md={12} lg={7} xl={7}
                >
                    {rightContent}
                </Grid>
            </Grid>
        </div>
    )
}

export default Introduction