import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

// COMPONENTS
import ScrollEffectHeader from '../ScrollEffectHeader/ScrollEffectHeader'

// CONTEXTS
import { HeaderContext } from '../../contexts/HeaderContext/HeaderContext'

// IMAGES
import IconDropdown from '../../images/icons/home_dropdown.svg'
import LogoLacakColor from '../../images/logos/lacakio_color.svg'

// MATERIAL UI CORES
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

// MATERIAL UI ICONS
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'

// STYLES
import useStyles from './headerUseStyles'

const Header = () => {
    const classes = useStyles()
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const { isDropdownOpen, changeIsDropdownOpen } = useContext(HeaderContext)

    const solutionDropdown =
    <div className={classes['solutionDropdownContainer']}>
        Solutions
        <img 
            src={IconDropdown} 
            alt='' 
            className={classes['solutionDropdown']}
        />
    </div>

    const menuOptions = [ 
        { name: solutionDropdown, path: '/solutions' },
        { name: 'Tentang', path: '/about' },
        { name: 'Blogs', path: '/blogs' },
        { name: 'FAQ', path: '/faq' },
    ]

    const menusComponent = isSmDown ?
    <>
        <IconButton 
            className={classes['menuIconContainer']} 
            onClick={() => changeIsDropdownOpen(!isDropdownOpen)}
        >
            {
                isDropdownOpen ? 
                <CloseIcon className={classes['menuIconBlack']}/> :
                <MenuIcon className={classes['menuIconBlack']}/>
            }
        </IconButton>
    </> :
    <>
        {
            menuOptions.map((item, index) => (
                <Link 
                    key={index} 
                    to={item['path']} 
                    className={classes['linkBlack']}
                >
                    <Typography 
                        variant='h6'
                        className={classes['appBarItem']}
                    >
                        {item['name']}
                    </Typography>
                </Link>
            ))
        }

        {/* CONTACT US */}
        <Button className={classes['contactUsButton']}>
            Hubungi Kami
        </Button>
    </>

    const dropDownMenu = 
    <div>
        <div className={classes['dropDownButtonContainer']}>
            {/* CONTACT US */}
            <Button className={classes['dropDownContactUsButton']}>
                Hubungi Kami
            </Button>
        </div>

        {/* MENU */}
        {
            menuOptions.map((item, index) => (
                <div key={index} className={classes['dropDownItem']}>
                    <Link 
                        key={index} 
                        to={item['path']} 
                        className={classes['linkBlack']}
                        onClick={() => changeIsDropdownOpen(false)}
                    >
                        <Typography 
                            variant='h6'
                            className={classes['dropDownText']}
                        >
                            {item['name']}
                        </Typography>
                    </Link>
                </div>

            ))
        }
    </div>

    useEffect(() => {
        if(!isSmDown) {
            changeIsDropdownOpen(false)
        }
    }, [isSmDown]) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className={isDropdownOpen ? classes['rootWithSidebar'] : classes['rootDefault']}>
            <ScrollEffectHeader
                threshold={0}
                boxShadow={'0px 8px 40px rgba(15, 160, 217, 0.05)'}
            >
                <AppBar 
                    position='fixed' 
                    className={classes['appBar']}
                >
                    <Toolbar disableGutters>
                        {/* LACAK LOGO */}
                        <Link to='/'>
                            <img 
                                src={isDropdownOpen ? LogoLacakColor : LogoLacakColor} 
                                alt='' 
                                className={classes['lacakLogo']}
                            />
                        </Link>

                        {/* MENUS*/}
                        {menusComponent}
                    </Toolbar>
                </AppBar>
            </ScrollEffectHeader>

            {/* DROPDOWN MENU */}
            {isDropdownOpen && dropDownMenu}
        </div>
    )
}

export default Header