// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        marginTop: 100,
    },
    currentSlideContainer: {
        backgroundColor: 'white',
        boxShadow: '0px 22px 40px rgba(15, 160, 217, 0.1)',
        borderRadius: 12,
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            height: 625,
        },
        [theme.breakpoints.only('sm')]: {
            height: 375,
        },
        [theme.breakpoints.only('md')]: {
            height: 350,
        },
        [theme.breakpoints.only('lg')]: {
            height: 380,
        },
        [theme.breakpoints.up('xl')]: {
            height: 550,
        },
    },
    personPhoto: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px 12px 0px 0px',
            height: 250,
        },
        [theme.breakpoints.only('sm')]: {
            borderRadius: '12px 0px 0px 12px',
            height: 375,
        },
        [theme.breakpoints.only('md')]: {
            borderRadius: '12px 0px 0px 12px',
            height: 350,
        },
        [theme.breakpoints.only('lg')]: {
            borderRadius: '12px 0px 0px 12px',
            height: 380,
        },
        [theme.breakpoints.up('xl')]: {
            borderRadius: '12px 0px 0px 12px',
            height: 550,
        },
    },
    contentContainer: {
        height: '100%',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '20px 40px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '30px 60px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '40px 80px',
        },
    },
    companyLogo: {
        // transform: 'translate(-10px, 0px)',
        [theme.breakpoints.down('sm')]: {
            width: 100,
            marginBottom: 10,
        },
        [theme.breakpoints.only('md')]: {
            width: 120,
            marginBottom: 10,
        },
        [theme.breakpoints.only('lg')]: {
            width: 150,
            marginBottom: 20,
        },
        [theme.breakpoints.up('xl')]: {
            width: 175,
            marginBottom: 50,
        },
    },
    personName: {
        fontWeight: 600,
        marginBottom: 10,
    },
    personPosition: {},
    line: {
        backgroundColor: colors['dodgerblue'],
        height: 3,
        width: 75,
        [theme.breakpoints.down('md')]: {
            margin: '20px 0px',
        },
        [theme.breakpoints.only('lg')]: {
            margin: '30px 0px',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '45px 0px',
        },
    },
    message: {},
    navigationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            marginTop: 30,
        },
        [theme.breakpoints.only('lg')]: {
            marginTop: 50,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 70,
        },
    },
    navigationText: {
        fontWeight: 600,
        color: colors['gainsboro'],
        display: 'flex',
    },
    blueText: {
        fontWeight: 600,
        color: colors['dodgerblue'],
    },
    navigationButton: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        boxShadow: '0px 10px 10px rgba(15, 160, 217, 0.1)',
        marginLeft: 20,
    },
}))

export default useStyles