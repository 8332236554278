// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 30px 15px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0px 60px 30px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 120px 60px ',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.only('lg')]: {
            padding: '0px 180px 90px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0px 0px 105px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    content: {
        [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: 1024,
        },
    },
    firstClosingRow: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 40,
        },
    },
}))

export default useStyles