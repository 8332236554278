// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,
            padding: 30,
        },
        [theme.breakpoints.only('sm')]: {
            marginTop: 60,
            padding: 60,
        },
        [theme.breakpoints.only('md')]: {
            padding: 120,
        },
        [theme.breakpoints.only('lg')]: {
            padding: 180,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 210,
        },
    },
    titleText: {
        textAlign: 'center',
        fontWeight: 600,
        lineHeight: 1.25,
    },
    captionText: {
        maxWidth: 900,
        textAlign: 'center',
        lineHeight: 1.5,
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            margin: '20px auto 20px',
        },
        [theme.breakpoints.only('sm')]: {
            margin: '20px auto 40px',
        },
        [theme.breakpoints.only('md')]: {
            margin: '20px auto 60px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '20px auto 80px',
        },
    },
    imageIndustries: {
        [theme.breakpoints.down('xs')]: {
            width: 250,
        },
        [theme.breakpoints.only('sm')]: {
            width: 406,
        },
        [theme.breakpoints.only('md')]: {
            width: 580,
        },
        [theme.breakpoints.only('lg')]: {
            width: 830,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1000,
        },
    },
}))

export default useStyles