// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        color: 'white',
        backgroundColor: colors['dodgerblue'],
        [theme.breakpoints.down('xs')]: {
            padding: '40px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '40px 40px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '50px 60px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '60px 80px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '70px 120px',
        },
    },
    topGrid: {
        marginBottom: 40,
        boxSizing: 'border-box',
    },
    companyName: {
        marginBottom: 20,
        fontWeight: 600,
        color: 'white',
    },
    companyLogo: {
        marginBottom: 30,
    },
    companyAddress: {
        color: 'white',
    },
    linkText: {
        textDecoration: 'none',
    },
    normalText: {
        marginBottom: 20,
        color: 'white',
    },
    copyrightContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        boxSizing: 'border-box',
        color: 'white',
    },
    copyrightText: {
        color: 'white',
    },
    socialMediaLogosContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    socialMediaLogo: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 35,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 35,
        },
    },
}))

export default useStyles