// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            padding: '15px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '30px 60px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '60px 120px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '90px 180px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '105px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    contentCointainer: {
        [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: 1024,
        },
    },
    itemContainer: {},
    itemTitleAndArrow: {
        display: 'flex',
        alignItems: 'center',
    },
    itemLine: {
        width: '100%',
        height: 4,
        backgroundColor: colors['aliceblue'],
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0px',
        },
        [theme.breakpoints.up('md')]: {
            margin: '30px 0px',
        },
    },
    itemLineLast: {
        marginBottom: 0,
    },
    itemTitle: {},
    itemArrow: {
        marginLeft: 'auto',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: 12,
        },
        [theme.breakpoints.only('md')]: {
            width: 16,
        },
        [theme.breakpoints.up('lg')]: {
            width: 20,
        },
    },
    itemDescription: {
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            marginRight: 25,
        },
        [theme.breakpoints.only('md')]: {
            marginRight: 50,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 75,
        },
    },
}))

export default useStyles