import React, { useState } from 'react'

// IMAGES
import Icon1 from '../../../images/icons/home_excellence_1.svg'
import Icon2 from '../../../images/icons/home_excellence_2.svg'
import Icon3 from '../../../images/icons/home_excellence_3.svg'
import Icon4 from '../../../images/icons/home_excellence_4.svg'
import Picture1 from '../../../images/pictures/home_excellence_image_1.svg'
import Picture2 from '../../../images/pictures/home_excellence_image_2.svg'
import Picture3 from '../../../images/pictures/home_excellence_image_3.svg'
import Picture4 from '../../../images/pictures/home_excellence_image_4.svg'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './excellenceUseStyles'

const Excellence = () => {
    const classes = useStyles()

    const title = 'Keunggulan Lacak.io'
    const caption = 'Seluruh solusi yang kami sediakan juga dilengkapi dengan berbagai fitur dan keunggulan dalam mengakselerasi bisnis Anda.'
    
    const contentList = [
        {
            title: 'Satu Dashboard untuk Semua',
            caption: 'Digitalisasi tata kelola armada menjadi lebih mudah berkat dashboard yang terintegrasi dan penyajian data yang mudah dipahami.',
            icon: Icon1,
            image: Picture1,
        },
        {
            title: 'Notifikasi Langsung',
            caption: 'Dapatkan notifikasi langsung untuk setiap permasalahan pada Armada, sehingga dapat melakukan penanganan cepat.',
            icon: Icon2,
            image: Picture2,
        },
        {
            title: 'Integrasi Sistem Pihak Ketiga',
            caption: 'Sistem kami bisa diintegrasikan dengan berbagai modul lainnya seperti aplikasi kelola transportasi, manajemen keuangan, dan lainnya.',
            icon: Icon3,
            image: Picture3,
        },
        {
            title: 'Dukungan Tim Handal',
            caption: 'Tim ahli kami siap membantu konsultasi dan implementasi solusi untuk meningkatkan kinerja perusahaan Anda.',
            icon: Icon4,
            image: Picture4,
        },
    ]

    const [ selectedIndex, setSelectedIndex ] = useState(0)
    const [ isFade, setIsFade ] = useState(true)

    const updateSelectedImage = (inputIdex) => {
        setSelectedIndex(inputIdex)
        setIsFade(true)
    }

    const selectedImageComponent =
    <Grid 
        item 
        xs={12} lg={6}
    >
        <img 
            src={contentList[selectedIndex]['image']} 
            alt='' 
            className={
                isFade ? 
                `${classes['selectedImage']} ${classes['selectedImageAnimation']}` : 
                classes['selectedImage']
            }
            onAnimationEnd={() => setIsFade(false)}
        />
    </Grid>

    const contentComponent =
    <Grid 
        item 
        xs={12} lg={6}
    >
        <div className={classes['contentContainer']}>
            {/* TITLE */}
            <Typography 
                variant='h4'
                className={classes['titleText']}
            >
                {title}
            </Typography>

            {/* CAPTION */}
            <Typography 
                variant='h5'
                className={classes['captionText']}
            >
                {caption}
            </Typography>

            {/* CONTENT LIST */}
            {
                contentList.map((item, index) => (
                    <div 
                        key={index}
                        className={
                            selectedIndex === index ? 
                            `${classes['contentItem']} ${classes['selectedContent']}` :
                            classes['contentItem']
                        }
                        // onMouseEnter={() => updateSelectedImage(index)}
                        onClick={() => updateSelectedImage(index)}
                    >
                        {/* ICON */}
                        <img 
                            src={item['icon']} 
                            alt='' 
                            className={classes['contentIcon']}
                        />

                        {/* TITLE AND CAPTION ITEM */}
                        <div className={classes['contentTitleAndCaption']}>
                            <Typography 
                                variant='h5'
                                className={classes['contentTitle']}
                            >
                                {item['title']}
                            </Typography>

                            <Typography 
                                variant='h6'
                                className={classes['contentCaption']}
                            >
                                {item['caption']}
                            </Typography>
                        </div>
                    </div>
                ))
            }
        </div>
    </Grid>

    return (
        <div className={classes['root']}>
            <Grid container>
                {selectedImageComponent}
                {contentComponent}
            </Grid>
        </div>
    )
}

export default Excellence