import React from 'react'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './closingUseStyles'

const Closing = (props) => {
    const { objectTypeData } = props

    const classes = useStyles()

    return (
        <div className={classes['root']}>
            <div className={classes['content']}>
                {
                    objectTypeData['data']['closing'] &&
                    objectTypeData['data']['closing'].split('\n').map((item, index) => (
                        <Typography 
                            key={index}
                            variant='h6'
                            className={index === 0 ? classes['firstClosingRow'] : ''}
                        >
                            {item}
                        </Typography>
                    ))
                }
            </div>
        </div>
    )
}

export default Closing