import React, { useContext } from 'react'

// CONTEXTS
import { ScreenSizesContext } from '../../../contexts/ScreenSizesContext/ScreenSizesContext'

// IMAGES
import PictureTracker from '../../../images/pictures/home_solution_tracker.svg'
import PictureFuel from '../../../images/pictures/home_solution_fuel.svg'
import PictureManpower from '../../../images/pictures/home_solution_manpower.svg'
import PictureFreight from '../../../images/pictures/home_solution_freight.svg'
import PictureTemperature from '../../../images/pictures/home_solution_temperature.svg' 
import IconExploreArrow from '../../../images/icons/home_explore_arrow.svg'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './ourSolutionsUseStyles'

const OurSolutions = () => {
    const classes = useStyles()

    const { isXsOnly, isSmOnly, isMdOnly, isLgOnly, isXlOnly } = useContext(ScreenSizesContext)

    let spacingGrid = 8
    if(isXlOnly) spacingGrid = 8
    else if(isLgOnly) spacingGrid = 7
    else if(isMdOnly) spacingGrid = 6
    else if(isSmOnly) spacingGrid = 5
    else if(isXsOnly) spacingGrid = 4

    const title = 'Platform Terintegrasi Untuk Mendukung Operasi Anda'
    const caption = 'Ragam teknologi kami telah banyak membantu memecahkan masalah di lapangan dan diimplementasikan oleh multi industri.'

    const solutionListTop = [
        {
            title: 'Live Fleet Tracker',
            caption: 'Ketahui keberadaan setiap armada secara aktual. Pantau jarak tempuh, jam kerja, dan kelola perawatan armada dengan lebih efisien.',
            image: PictureTracker,
        },
        {
            title: 'Fuel Consumption Monitoring',
            caption: 'Pantau konsumsi bahan bakar dengan tingkat akurasi mencapai 99%. Hindari kecurangan pada saat pengisian bahan bakar seluruh armada.',
            image: PictureFuel,
        },
        {
            title: 'Manpower Management App',
            caption: 'Maksimalkan kinerja di lapangan lewat aplikasi mobile untuk para pekerja. Rasakan kemudahan dalam distribusi tugas dan monitor performa.',
            image: PictureManpower,
        },
        {
            title: 'Freight Load Monitoring',
            caption: 'Monitor beban muatan pada armada dan memastikan sesuai dengan SOP perusahaan. Hindari biaya kerusakan akibat muatan berlebihan.',
            image: PictureFreight,
        },
        {
            title: 'Temperature & Humidity Detector',
            caption: 'Deteksi suhu dan kelembaban udara muatan barang yang membutuhkan temperatur optimal selama perjalanan.',
            image: PictureTemperature,
        },
    ]
 
    const titleAndCaption =
    <Grid 
        item 
        xs={12} md={6} lg={4}
    >
        {/* TITLE */}
        <Typography 
            variant='h4'
            className={classes['titleText']}
        >
            {title}
        </Typography>

        {/* CAPTION */}
        <Typography 
            variant='h5'
            className={classes['captionText']}
        >
            {caption}
        </Typography>

        {/* LINE */}
        <div className={classes['line']}></div>
    </Grid>

    const solutionListTopComponent =
    <Grid 
        container
        spacing={spacingGrid}
    >
        {titleAndCaption}
        {
            solutionListTop.map((item, index) => (
                <Grid 
                    key={index} 
                    item 
                    xs={12} md={6} lg={4}
                >
                    <div className={classes['gridItemTopContainer']}>
                        {/* IMAGE */}
                        <img 
                            src={item['image']} 
                            alt='' 
                            className={classes['gridItemTopImage']}
                        />

                        {/* CONTENT */}
                        <div className={classes['gridItemTopContent']}>
                            {/* ITEM TITLE */}
                            <Typography 
                                variant='h5'
                                className={classes['gridItemTitle']}
                            >
                                {item['title']}
                            </Typography>

                            {/* ITEM CAPTION */}
                            <Typography 
                                variant='h6'
                                className={classes['gridItemCaption']}
                            >
                                {item['caption']}
                            </Typography>

                            {/* EXPLORE MORE */}
                            <Typography 
                                variant='h6'
                                className={classes['gridItemExploreContainer']}
                            >
                                Pelajari 
                                <img 
                                    src={IconExploreArrow} 
                                    alt=''
                                    className={classes['gridItemExploreIcon']}
                                />
                            </Typography>
                        </div>

                    </div>
                </Grid>
            ))
        }
    </Grid>

    return (
        <div className={classes['root']}>
            {solutionListTopComponent}
        </div>
    )
}

export default OurSolutions