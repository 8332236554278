import React, { useState } from 'react'

const HeaderContext = React.createContext()

const HeaderContextProvider = (props) => {
    const [ isDropdownOpen, setIsDropdownOpen ] = useState(false)
    
    const changeIsDropdownOpen = (inputState) => {
        setIsDropdownOpen(inputState)
    }

    return (
        <HeaderContext.Provider
            value={{
                isDropdownOpen,
                changeIsDropdownOpen
            }}
        >
            {props['children']}
        </HeaderContext.Provider>
    )
}

export { HeaderContextProvider, HeaderContext }
