import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// COMPONENTS (SORTED BY ORDER)
import Opening from './Opening/Opening'
import Content from './Content/Content'
import Closing from './Closing/Closing'

// STYLES
import useStyles from './rulesUseStyles'

// UTILS
import { 
    apiEndPointList,
    fetchRuleAPI, 
} from '../../utils/fetchRuleAPI'

const Rules = () => {
    const classes = useStyles()
    
    const locationUrl = useLocation()
    const pageName = locationUrl['pathname'].split('/')[1]

    const [ objectTypeContent, setObjectTypeContent ] = useState(null)
    const [ listTypeContent, setListTypeContent ] = useState(null)

    const getRulesData = async () => {
        let objectTypeEndPoint, listTypeEndPoint
        if(pageName === 'privacy-policy') {
            objectTypeEndPoint = apiEndPointList['privacyPolicyObject']
            listTypeEndPoint = apiEndPointList['privacyPolicyList']
        }
        else if(pageName === 'terms-of-service') {
            objectTypeEndPoint = apiEndPointList['termsOfServiceObject']
            listTypeEndPoint = apiEndPointList['termsOfServiceList']
        } 
        else if(pageName === 'support-regulation') {
            objectTypeEndPoint = apiEndPointList['supportRegulationObject']
            listTypeEndPoint = apiEndPointList['supportRegulationList']
        } 

        const objectTypeData = await fetchRuleAPI(objectTypeEndPoint)
        const listTypeData = await fetchRuleAPI(listTypeEndPoint)

        setObjectTypeContent(objectTypeData)
        setListTypeContent(listTypeData)
    }

    useEffect(() => {
        getRulesData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes['root']}>
            {
                (objectTypeContent && listTypeContent) &&
                <>
                    {/* OPENING SECTION */}
                    <Opening 
                        objectTypeData={objectTypeContent}
                    />
        
                    {/* CONTENT SECTION */}
                    <Content 
                        listTypeData={listTypeContent}
                    />
        
                    {/* CLOSING SECTION */}
                    <Closing 
                        objectTypeData={objectTypeContent}
                    />
                </>
            }
        </div>
    )
}

export default Rules