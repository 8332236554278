import React, { useState } from 'react'

// IMAGES
import IconNext from '../../../../images/icons/home_carousell_next.svg'
import IconPrevious from '../../../../images/icons/home_carousell_previous.svg'
import LogoSbm from '../../../../images/logos/home_companies_sbm.svg'
import PicturePerson1 from '../../../../images/pictures/home_carousell_person_1.png'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './carousellUseStyles'

const Carousell = () => {
    const classes = useStyles()

    const [ currentSlide, setCurrentSlide ] = useState(0)

    const dataList = [
        {
            personPhoto: PicturePerson1,
            companyLogo: LogoSbm,
            personName: 'Inggrid J. Indra',
            personPosition: 'Business Development Director',
            message: 'Lacak apps is a very friendly apps and very accurate in tracking our daily operation needs.',
        },
        {
            personPhoto: PicturePerson1,
            companyLogo: LogoSbm,
            personName: 'Lorem Ipsum 2',
            personPosition: 'Lorem Ipsum 2',
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry 2',
        },
        {
            personPhoto: PicturePerson1,
            companyLogo: LogoSbm,
            personName: 'Lorem Ipsum 3',
            personPosition: 'Lorem Ipsum 3',
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry 3',
        },
    ]

    const goToNextSlide = () => {
        setCurrentSlide(current => current === dataList.length - 1 ? 0 : current + 1)
    }

    const goToPreviousSlide = () => {
        setCurrentSlide(current => current === 0 ? dataList.length - 1 : current - 1)
    }

    const slideImageComponent =
    <Grid 
        item 
        xs={12} sm={4}
    >
        <img 
            src={dataList[currentSlide]['personPhoto']} 
            alt='' 
            className={classes['personPhoto']}
        />
    </Grid>

    const slideContentComponent =
    <Grid 
        item 
        xs={12} sm={8}
    >
        <div className={classes['contentContainer']}>
            {/* COMPANY LOGO */}
            <img 
                src={dataList[currentSlide]['companyLogo']} 
                alt='' 
                className={classes['companyLogo']}
            />

            {/* PERSON NAME */}
            <Typography 
                variant='h5'
                className={classes['personName']}
            >
                {dataList[currentSlide]['personName']}
            </Typography>

            {/* PERSON POSITION */}
            <Typography variant='h5'>
                {dataList[currentSlide]['personPosition']}
            </Typography>

            {/* LINE */}
            <div className={classes['line']}></div>

            {/* MESSAGE */}
            <Typography variant='h5'>
                {dataList[currentSlide]['message']}
            </Typography>
        </div>
    </Grid>

    const navigationComponent =
    <div className={classes['navigationContainer']}>
        {/* TEXT */}
        <Typography 
            variant='h5'
            className={classes['navigationText']}
        >
            <Typography
                variant='h5'
                className={classes['blueText']}
            >
                {`0${currentSlide + 1}`}
            </Typography>
            &nbsp;
            {`  / 0${dataList.length}`}
        </Typography>

        {/* PREVIOUS BUTTON */}
        <img 
            src={IconPrevious} 
            alt='' 
            className={classes['navigationButton']}
            onClick={() => goToPreviousSlide()}
        />

        {/* NEXT BUTTON */}
        <img 
            src={IconNext} 
            alt='' 
            className={classes['navigationButton']}
            onClick={() => goToNextSlide()}
        />
    </div>

    return (
        <div className={classes['root']}>
            {/* CURRENT SLIDE */}
            <div className={classes['currentSlideContainer']}>
                <Grid container>
                    {slideImageComponent}
                    {slideContentComponent}
                </Grid>
            </div>

            {/* NAVIGATION */}
            {navigationComponent}
        </div>
    )
}

export default Carousell