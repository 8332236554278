// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        background: 'linear-gradient(360deg, rgba(15, 160, 217, 0.05) 18.43%, rgba(15, 160, 217, 0) 91.98%)',
        [theme.breakpoints.down('xs')]: {
            padding: '30px 30px 0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '60px 60px 0px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '60px 120px 0px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '90px 180px 0px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '105px 210px 0px',
        },
    },
    topTitleText: {
        fontWeight: 600,
        lineHeight: 1.25,
        textAlign: 'center',
    },
    topCaptionText: {
        maxWidth: 900,
        lineHeight: 1.5,
        fontWeight: 400,
        margin: '20px auto 30px',
        textAlign: 'center',
    },
    pictureIllustrationContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    pictureIllustration: {
        [theme.breakpoints.down('xs')]: {
            width: 300,
            marginTop: 50,
        },
        [theme.breakpoints.only('sm')]: {
            width: 400,
            marginTop: 50,
        },
        [theme.breakpoints.only('md')]: {
            width: 500,
        },
        [theme.breakpoints.only('lg')]: {
            width: 700,
        },
        [theme.breakpoints.up('xl')]: {
            width: 900,
        },
    },
    bottomSectionContainter: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,
        },
        [theme.breakpoints.only('sm')]: {
            marginTop: 60,
        },
        [theme.breakpoints.only('md')]: {
            marginTop: 120,
        },
        [theme.breakpoints.only('lg')]: {
            marginTop: 180,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 210,
        },
    },
    bottomLeftContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px 20px 0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0px 0px 40px 0px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 0px 60px 0px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '0px 80px 40px 0px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0px 120px 120px 0px',
        },
    },
    bottomTitleText: {
        fontWeight: 600,
        lineHeight: 1.25,
        marginBottom: 30,
    },
    bottomCaptionText: {
        lineHeight: 1.5,
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
            marginBottom: 30,
        },
        [theme.breakpoints.only('lg')]: {
            marginBottom: 40,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 60,
        },
    },
    contactUsButton: {
        backgroundColor: colors['deepskyblue'],
        textTransform: 'none',
        padding: '10px 30px',
        '&:hover': {
            background: colors['deepskyblue'],
        },
    },
    contactUsText: {
        color: 'white',
        fontWeight: 600,
    },
}))

export default useStyles