// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        background: 'linear-gradient(360deg, rgba(15, 160, 217, 0.05) -45.3%, rgba(15, 160, 217, 0) 91.98%)',
        [theme.breakpoints.down('xs')]: {
            padding: '30px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '60px 60px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '60px 120px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '90px 180px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '105px 210px',
        },
    },
    titleText: {
        fontWeight: 600,
        lineHeight: 1.25,
    },
    captionText: {
        maxWidth: 900,
        lineHeight: 1.5,
        fontWeight: 400,
        marginTop: 20,
        marginBottom: 30,
    },
    line: {
        width: 135,
        height: 4,
        backgroundColor: colors['dodgerblue']
    },
    gridItemTopContainer: {
        borderRadius: 20,
        boxShadow: '0px 10px 40px rgba(15, 160, 217, 0.1)',
        transform: 'scale(1)',
        transition: 'transform 0.25s ease-in-out',
        '&:hover': {
            transform: 'scale(1.025)',
            transition: 'transform 0.25s ease-in-out',
        }
    },
    gridItemTopImage: {
        width: '100%',
    },
    gridItemTopContent: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            padding: 20,
            height: 325,
        },
        [theme.breakpoints.only('sm')]: {
            padding: 20,
            height: 275,
        },
        [theme.breakpoints.only('md')]: {
            padding: 30,
            height: 400,
        },
        [theme.breakpoints.only('lg')]: {
            padding: 30,
            height: 500,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 40,
            height: 350,
        },
    },
    gridItemTitle: {
        fontWeight: 600,
        marginBottom: 20,
    },
    gridItemCaption: {
        fontWeight: 400,
    },
    gridItemExploreContainer: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        color: colors['dodgerblue'],
        cursor: 'pointer',
        marginRight: 'auto',
        fontWeight: 400,
    },
    gridItemExploreIcon: {
        marginLeft: 10,
        width: 15,
    },
}))

export default useStyles