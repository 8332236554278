// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
    rootDefault: {
        flexGrow: 1,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    rootWithSidebar: {
        flexGrow: 1,
        width: '100%',
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        justifyContent: 'center',
        height: 100,
        [theme.breakpoints.down('xs')]: {
            padding: '0px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0px 40px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 60px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '0px 80px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0px 120px',
        },
    },
    lacakLogo: { 
        width: 150,
        height: 50,
        marginTop: 7
    },
    menuIconContainer: {
        padding: '0px',
        marginLeft: 'auto'
    },
    menuIconBlack: {
        fontSize: 40,
    },
    appBarItem: {
        [theme.breakpoints.only('md')]: {
            margin: '0px 15px'
        },
        [theme.breakpoints.only('lg')]: {
            margin: '0px 30px'
        },
        [theme.breakpoints.up('xl')]: {
            margin: '0px 40px'
        },
        fontWeight: 600,
    },
    linkBlack: {
        textDecoration: 'none',
    },
    solutionDropdownContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    solutionDropdown: {
        width: 10,
        marginLeft: 10,
    },
    contactUsButton: {
        backgroundColor: colors['deepskyblue'],
        textTransform: 'none',
        [theme.breakpoints.only('md')]: {
            padding: '10px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 30px',
        },
        color: 'white',
        marginLeft: 'auto',
        '&:hover': {
            background: colors['deepskyblue'],
        },
        fontSize: 16,
        fontWeight: 600,
    },
    dropDownItem: {
        [theme.breakpoints.down('xs')]: {
            padding: '15px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '15px 40px',
        },
        borderBottom: `1px solid ${colors['silver']}`
    },
    dropDownText: {
        fontWeight: 600,
    },
    dropDownButtonContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '10px 40px',
        },
        marginTop: 100,
    },
    dropDownContactUsButton: {
        backgroundColor: colors['deepskyblue'],
        textTransform: 'none',
        padding: '10px 30px',
        width: '100%',
        color: 'white',
        '&:hover': {
            background: colors['deepskyblue'],
        },
        fontSize: 18,
        fontWeight: 600,
    }
}))

export default useStyles