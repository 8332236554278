// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            padding: '30px 0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '60px 0px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '60px 0px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '90px 0px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '105px 0px',
        },
    },
    selectedImage: {
        marginTop: 40,
        width: '100%',
    },
    selectedImageAnimation: {
        animation: '$fadeIn 0.25s ease-in-out',
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1
        },
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: '0px 30px 0px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0px 60px 0px 60px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 120px 0px 60px',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '0px 180px 0px 90px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0px 210px 0px 105px',
        },
    },
    titleText: {
        fontWeight: 600,
        lineHeight: 1.25,
        marginBottom: 30,
    },
    captionText: {
        lineHeight: 1.5,
        fontWeight: 400,
        marginBottom: 25,
    },
    contentItem: {
        display: 'flex',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        borderRadius: 6,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            padding: 20,
        },
        [theme.breakpoints.only('sm')]: {
            padding: 20,
        },
        [theme.breakpoints.only('md')]: {
            padding: 20,
        },
        [theme.breakpoints.only('lg')]: {
            padding: 25,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 25,
        },
    },
    selectedContent: {
        backgroundColor: colors['aliceblue'],
    },
    contentIcon: {
        [theme.breakpoints.down('xs')]: {
            width: 45,
            marginRight: 20,
        },
        [theme.breakpoints.only('sm')]: {
            width: 45,
            marginRight: 20,
        },
        [theme.breakpoints.only('md')]: {
            width: 45,
            marginRight: 20,
        },
        [theme.breakpoints.only('lg')]: {
            width: 65,
            marginRight: 20,
        },
        [theme.breakpoints.up('xl')]: {
            width: 80,
            marginRight: 25,
        },
    },
    contentTitleAndCaption: {},
    contentTitle: {
        fontWeight: 600,
        marginBottom: 10,
    },
    contentCaption: {},
}))

export default useStyles