import React from 'react'

// COMPONENTS
import Carousell from './Carousell/Carousell'

// IMAGES
import PictureIllustration from '../../../images/pictures/home_testimony_illustration.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './testimonyUseStyles'

const Testimony = () => {
    const classes = useStyles()

    const topTitle = 'Testimoni Clients & Partner'
    const topCaption = 'Kami telah dipercaya oleh berbagai klien dan mitra di level nasional maupun internasional.'
    
    const bottomTitle = 'Tidak menemukan yang Anda cari?'
    const bottomCaption = 'Kami masih memiliki ragam solusi lainnya dalam tahap pengembangan. Konsultasikan kepada tim ahli kami mengenai kebutuhan Anda!'

    const titleAndCaption =
    <>
        {/* TITLE */}
        <Typography 
            variant='h4'
            className={classes['topTitleText']}
        >
            {topTitle}
        </Typography>

        {/* CAPTION */}
        <Typography 
            variant='h5'
            className={classes['topCaptionText']}
        >
            {topCaption}
        </Typography>
    </>

    const bottomSection =
    <Grid 
        container 
        className={classes['bottomSectionContainter']}
    >
        {/* BOTTOM CONTENT */}
        <Grid 
            item 
            xs={12} lg={5}
            className={classes['bottomLeftContent']}
        >
            {/* BOTTOM TITLE */}
            <Typography 
                variant='h4'
                className={classes['bottomTitleText']}
            >
                {bottomTitle}
            </Typography>

            {/* BOTTOM CAPTION */}
            <Typography 
                variant='h5'
                className={classes['bottomCaptionText']}
            >
                {bottomCaption}
            </Typography>

            {/* CONTACT US */}
            <Button className={classes['contactUsButton']}>
                <Typography 
                    variant='h6'
                    className={classes['contactUsText']}
                >
                    Hubungi Kami
                </Typography>
            </Button>
        </Grid>

        {/* ILLUSTRATION */}
        <Grid 
            item 
            xs={12} lg={7}
            className={classes['pictureIllustrationContainer']}
        >
            <img 
                src={PictureIllustration} 
                alt='' 
                className={classes['pictureIllustration']}
            />
        </Grid>
    </Grid>

    return (
        <div className={classes['root']}>
            {titleAndCaption}
            <Carousell/>
            {bottomSection}
        </div>
    )
}

export default Testimony