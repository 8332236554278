// MATERIAL UI CORES
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// UTILS
import colors from './colors'

let fontTheme = createTheme({
    typography: {
        fontFamily: [
            'Poppins', 'sans-serif'
        ].join(','),
        allVariants: {
            color: colors['midNightblue'],
        },
    },
})

fontTheme = responsiveFontSizes(fontTheme) 

export default fontTheme