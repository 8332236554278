// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
        background: 'linear-gradient(293.93deg, rgba(15, 160, 217, 0.05) -1.76%, rgba(15, 160, 217, 0) 90.23%)',
        [theme.breakpoints.down('xs')]: {
            padding: '15px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '30px 60px',
        },
        [theme.breakpoints.only('md')]: {
            padding: '60px 120px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.only('lg')]: {
            padding: '90px 180px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '105px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    content: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 1024,
        },
    },
    titleText: {
        fontWeight: 600,
    },
    effectiveDate: {
        [theme.breakpoints.down('sm')]: {
            margin: '30px 0px 20px 0px',
        },
        [theme.breakpoints.up('md')]: {
            margin: '50px 0px 30px 0px',
        },
    },
    message: {
        lineHeight: 1.5,
    },
}))

export default useStyles