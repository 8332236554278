import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// CONTEXTS
import { HeaderContextProvider } from './contexts/HeaderContext/HeaderContext'
import { ScreenSizesContextProvider } from './contexts/ScreenSizesContext/ScreenSizesContext'

// MATERIAL UI
import { ThemeProvider } from '@material-ui/core/styles'

// STYLES
import './index.css'

// UTILS
import fontConfig from './utils/fontConfig'

// DUMMY COMMENT

ReactDOM.render(
  <ThemeProvider theme={fontConfig}>
    <ScreenSizesContextProvider>
      <HeaderContextProvider>
        <App />
      </HeaderContextProvider>
    </ScreenSizesContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
)