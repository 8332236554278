import React, { useState } from 'react'

// IMAGES
import IconArrowDown from '../../../images/icons/rules_arrow_down.svg'
import IconArrowUp from '../../../images/icons/rules_arrow_up.svg'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './contentUseStyles'

const ContentRule = (props) => {
    const { listTypeData } = props
    
    const classes = useStyles()

    const [ isItemOpenList, setIsItemOpenList ] = useState(Array(listTypeData['data'].length).fill(false))

    const changeIsItemOpenList = (inputIndex) => {
        let currentValueList = [...isItemOpenList]
        currentValueList[inputIndex] = !currentValueList[inputIndex]
        setIsItemOpenList(currentValueList)
    }

    const listComponent = listTypeData['data'].map((item, index) => (
        <div 
            key={index}
            className={classes['itemContainer']}
        >
            {/* TITLE AND ARROW */}
            <div className={classes['itemTitleAndArrow']}>
                {/* TITLE */}
                <Typography 
                    variant='h5'
                    className={classes['itemTitle']}
                >
                    {item['title']}
                </Typography>

                {/* ARROW */}
                <img 
                    src={isItemOpenList[index] ? IconArrowUp : IconArrowDown} 
                    alt='' 
                    className={classes['itemArrow']}
                    onClick={() => changeIsItemOpenList(index)}
                />
            </div>

            {/* DESCRIPTION */}
            {
                isItemOpenList[index] &&
                item['description'].split('\n').map((paragraphItem, paragraphIndex) => (
                    <Typography 
                        key={paragraphIndex}
                        variant='h6'
                        className={classes['itemDescription']}
                    >
                        {paragraphItem}
                    </Typography>
                ))
            }

            {/* LINE */}
            <div 
                className={
                    index === listTypeData['data'].length - 1 ?
                    `${classes['itemLine']} ${classes['itemLineLast']}` :
                    classes['itemLine']
                }
            />
        </div>
    ))

    return (
        <div className={classes['root']}>
            <div className={classes['contentCointainer']}>
                {listComponent}
            </div>
        </div>
    )
}

export default ContentRule