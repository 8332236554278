import React from 'react'

// COMPONENTS (SORTED BY ORDER)
import Introduction from './Introduction/Introduction'
import Companies from './Companies/Companies'
import OurSolutions from './OurSolutions/OurSolutions'
import Industries from './Industries/Industries'
import Excellence from './Excellence/Excellence'
import Testimony from './Testimony/Testimony'

const Home = () => {
    return (
        <>
            <Introduction/>
            <Companies/>
            <OurSolutions/>
            <Industries/>
            <Excellence/>
            <Testimony/>
        </>
    )
}

export default Home