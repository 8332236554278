import React from 'react'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './openingUseStyles'

const Opening = (props) => {
    const { objectTypeData } = props

    const classes = useStyles()

    return (
        <div className={classes['root']}>
            <div className={classes['content']}>
                {/* TITLE */}
                <Typography 
                    variant='h4'
                    className={classes['titleText']}
                >
                    {objectTypeData['data']['title']}
                </Typography>

                {/* EFFECTIVE DATE */}
                <Typography 
                    variant='h5'
                    className={classes['effectiveDate']}
                >
                    Effective Date: {objectTypeData['data']['effective_date']}
                </Typography>

                {/* MESSAGE */}
                <Typography 
                    variant='h5'
                    className={classes['message']}
                >
                    {objectTypeData['data']['opening']}
                </Typography>
            </div>
        </div>
    )
}

export default Opening