// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        background: 'linear-gradient(293.93deg, rgba(15, 160, 217, 0.05) -1.76%, rgba(15, 160, 217, 0) 90.23%)',
        [theme.breakpoints.down('md')]: {
            minHeight: 800,
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 800,
        },
    },
    negativeMarginTop: {
        marginTop: -100,
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 30px 0px 30px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0px 40px 0px 40px',
            height: '50vh',
            minHeight: 400,
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 60px 0px 60px',
            height: '50vh',
            minHeight: 400,
        },
        [theme.breakpoints.only('lg')]: {
            padding: '0px 0px 0px 80px',
            height: '100vh',
            minHeight: 800,
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0px 0px 0px 210px',
            height: '100vh',
            minHeight: 800,
        },
    },
    titleText: {
        lineHeight: 1.25,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            fontSize: '42px',
        },
    },
    captionText: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: 40,
        },
        [theme.breakpoints.only('md')]: {
            marginBottom: 60,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 100,
        },
        marginTop: 20,
        fontWeight: 400,
        lineHeight: 1.5,
    },
    contactUsButton: {
        backgroundColor: colors['deepskyblue'],
        textTransform: 'none',
        padding: '10px 30px',
        color: 'white',
        '&:hover': {
            background: colors['deepskyblue'],
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            fontWeight: 600,
        },
    },
    rightContent: {
        [theme.breakpoints.down('md')]: {
            height: '50vh',
            minHeight: 400,
        },
        [theme.breakpoints.up('lg')]: {
            height: '100vh',
            minHeight: 800,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    banner: {
        [theme.breakpoints.down('xs')]: {
            width: 350,
            alignSelf: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            width: 550,
            alignSelf: 'center',
        },
        [theme.breakpoints.only('md')]: {
            width: 550,
            alignSelf: 'center',
        },
        [theme.breakpoints.only('lg')]: {
            width: 700,
            marginTop: 50,
            marginRight: 55,
        },
        [theme.breakpoints.up('xl')]: {
            width: 900,
            marginTop: 100,
            marginRight: 170,
        },
    },
}))

export default useStyles