import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

// CONTEXTS
import { ScreenSizesContext } from '../../contexts/ScreenSizesContext/ScreenSizesContext'

// IMAGES
import LogoLacakWhite from '../../images/logos/lacakio_white.svg'
import LogoFacebook from '../../images/logos/footer_facebook.svg'
import LogoLinkedin from '../../images/logos/footer_linkedin.svg'
import LogoInstagram from '../../images/logos/footer_instagram.svg'
import LogoYoutube from '../../images/logos/footer_youtube.svg'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './footerUseStyles'

const Footer = () => {
    const classes = useStyles()

    const { isXsOnly, isSmOnly, isMdOnly, isLgOnly, isXlOnly } = useContext(ScreenSizesContext)

    let gridItemWidth, gridSpacing
    if(isLgOnly || isXlOnly) {
        gridItemWidth = 3
        gridSpacing = 5
    }
    else if(isMdOnly) {
        gridItemWidth = 6
        gridSpacing = 3
    }
    else if(isSmOnly || isXsOnly) {
        gridItemWidth = 12
        gridSpacing = 2
    }
    
    const companyName = 'PT Lacak Cipta Aktual'
    const companyAddress = '50/F, Menara BCA Grand Indonesia\nJl. MH. Thamrin No. 1\nJakarta Pusat, 10310'
    
    const footerList = [
        [
            {
                name: 'Live Fleet Tracker',
                path: '/',
            },
            {
                name: 'Fuel Consumpton Monitoring',
                path: '/',
            },
            {
                name: 'Manpower Management',
                path: '/',
            },
            {
                name: 'Freight Load Monitoring',
                path: '/',
            },
            {
                name: 'Temperature & Humidity Detector',
                path: '/',
            },
        ],
        [
            {
                name: 'Tentang Lacak',
                path: '/',
            },
            {
                name: 'FAQ',
                path: '/',
            },
        ],
        [
            {
                name: 'Privacy Policy',
                path: '/privacy-policy',
            },
            {
                name: 'Terms of Service',
                path: '/terms-of-service',
            },
            {
                name: 'Support Regulation',
                path: '/support-regulation',
            },
        ],
    ]

    const copyright = '© 2017-2021 Lacak.io. Mobile Resource Management platform. All rights reserved'

    const socialMediaList = [ LogoFacebook, LogoLinkedin, LogoInstagram, LogoYoutube ]

    const companyIdentityComponnent =
    <Grid 
        item 
        xs={gridItemWidth}
    >
        {/* COMPANY LOGO */}
        <img 
            src={LogoLacakWhite} 
            alt='' 
            className={classes['companyLogo']}
        />

        {/* COMPANY NAME */}
        <Typography 
            variant='h6'
            className={classes['companyName']}
        >
            {companyName}
        </Typography>

        {/* COMPANY ADDRESS */}
        {
            companyAddress.split('\n').map((item, index) => (
                <Typography 
                    key={index}
                    variant='h6'
                    className={classes['companyAddress']}
                >
                    {item}
                </Typography>
            ))
        }
    </Grid>

    const companyLinks = footerList.map((gridItem, gridIndex) => (
        <Grid 
            key={gridIndex}
            item 
            xs={gridItemWidth}
        >
            {
                gridItem.map((item, index) => (
                    <Link 
                        to={item['path']}
                        className={classes['linkText']}
                    >
                        <Typography 
                            key={index}
                            variant='h6'
                            className={classes['normalText']}
                        >
                            {item['name']}
                        </Typography>
                    </Link>
                ))
            }
        </Grid>
    ))

    const copyrightAndSocialMediaLogos =
    <Grid 
        container
        spacing={gridSpacing}
    >
        {/* COPYRIGHT */}
        <Grid 
            item 
            xs={12} md={8}
            className={classes['copyrightContainer']}
        >
            <Typography 
                variant='subtitle1'
                className={classes['copyrightText']}
            >
                {copyright}
            </Typography>
        </Grid>

        {/* SOCIAL MEDIA LOGOS */}
        <Grid 
            item 
            xs={12} md={4}
            className={classes['socialMediaLogosContainer']}
        >
            {
                socialMediaList.map((item, index) => (
                    <img 
                        key={index}
                        src={item} 
                        alt=''
                        className={classes['socialMediaLogo']}
                    />
                ))
            }
        </Grid>
    </Grid>

    return (
        <div className={classes['root']}>
            <Grid 
                container
                className={classes['topGrid']}
                spacing={gridSpacing}
            >
                {companyIdentityComponnent}
                {companyLinks}
            </Grid>
            {copyrightAndSocialMediaLogos}
        </div>
    )
}

export default Footer