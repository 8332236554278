const strapiBaseUrl = process.env.REACT_APP_DIRECTUS_API_BASE

// BASE: cmsd.lacak.io/items/
const apiEndPointList = {
    privacyPolicyList: 'privacy_policy_list',
    privacyPolicyObject: 'privacy_policy_object',
    supportRegulationList: 'support_regulation_list',
    supportRegulationObject: 'support_regulation_object',
    termsOfServiceList: 'terms_of_service_list',
    termsOfServiceObject: 'terms_of_service_object',
}

// const TAG = 'fetchRuleAPI'

const fetchRuleAPI = async (inputEndPoint) => {
    const COMPLETEURL = `${strapiBaseUrl}${inputEndPoint}`

    const response = await fetch(COMPLETEURL, {
        method: 'GET',
    })
    // console.log(`${TAG}, response: `, response)
    const responseJson = await response.json()
    // console.log(`${TAG}, reponseJson: `, responseJson)
    return responseJson
}

export { fetchRuleAPI, apiEndPointList } 