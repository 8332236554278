import React, { useContext } from 'react'

// CONTEXTS
import { ScreenSizesContext } from '../../../contexts/ScreenSizesContext/ScreenSizesContext'

// IMAGES
import PictureIndustriesWithText from '../../../images/pictures/home_industries_with_text.svg'
import PictureIndustriesNoText from '../../../images/pictures/home_industries_no_text.svg'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './industriesUseStyles'

const Industries = () => {
    const classes = useStyles()

    const { isXsOnly, isSmOnly, isMdOnly, isLgOnly, isXlOnly } = useContext(ScreenSizesContext)

    let pictureIndustries = PictureIndustriesWithText
    if(isMdOnly || isLgOnly || isXlOnly) pictureIndustries = PictureIndustriesWithText
    else if(isXsOnly || isSmOnly) pictureIndustries = PictureIndustriesNoText

    const title = 'Dibangun Untuk Kebutuhan Industri Anda'
    const caption = 'Kenali solusi terbaik untuk setiap jenis usaha. Integrasi teknologi dapat menjawab kebutuhan setiap perusahaan dalam persaingan di era kini.'

    const titleAndCaption =
    <>
        {/* TITLE */}
        <Typography 
            variant='h4'
            className={classes['titleText']}
        >
            {title}
        </Typography>

        {/* CAPTION */}
        <Typography 
            variant='h5'
            className={classes['captionText']}
        >
            {caption}
        </Typography>
    </>

    return (
        <div className={classes['root']}>
            {/* TITLE AND CAPTION */}
            {titleAndCaption}

            {/* IMAGE */}
            <img 
                src={pictureIndustries} 
                alt='' 
                className={classes['imageIndustries']}
            />
        </div>
    )
}

export default Industries