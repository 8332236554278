// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

// IMAGE DIMENSIONS
const logoDimensions = {
    logoCompanyTransporta: {
        width: 210,
        height: 120,
    },
    logoCompanyMit: {
        width: 120,
        height: 50,
    },
    logoCompanyValid: {
        width: 200,
        height: 50,
    },
    logoCompanySbm: {
        width: 180,
        height: 85,
    },
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '30px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '50px 0px',
        },
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            width: 300,
        },
        [theme.breakpoints.only('sm')]: {
            width: 500,
        },
        [theme.breakpoints.only('md')]: {
            width: 900,
        },
        [theme.breakpoints.up('lg')]: {
            width: 960,
        },
        margin: '0px auto',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    logoCompanyTransporta: {
        [theme.breakpoints.down('sm')]: {
            width: logoDimensions['logoCompanyTransporta']['width'] * 0.75,
            height: logoDimensions['logoCompanyTransporta']['width'] * 0.75,
        },
        [theme.breakpoints.up('md')]: {
            width: logoDimensions['logoCompanyTransporta']['width'],
            height: logoDimensions['logoCompanyTransporta']['width'],
        },
    },
    logoCompanyMit: {
        [theme.breakpoints.down('sm')]: {
            width: logoDimensions['logoCompanyMit']['width'] * 0.75,
            height: logoDimensions['logoCompanyMit']['width'] * 0.75,
        },
        [theme.breakpoints.up('md')]: {
            width: logoDimensions['logoCompanyMit']['width'],
            height: logoDimensions['logoCompanyMit']['width'],
        },
    },
    logoCompanyValid: {
        [theme.breakpoints.down('sm')]: {
            width: logoDimensions['logoCompanyValid']['width'] * 0.75,
            height: logoDimensions['logoCompanyValid']['width'] * 0.75,
        },
        [theme.breakpoints.up('md')]: {
            width: logoDimensions['logoCompanyValid']['width'],
            height: logoDimensions['logoCompanyValid']['width'],
        },
    },
    logoCompanySbm: {
        [theme.breakpoints.down('sm')]: {
            width: logoDimensions['logoCompanySbm']['width'] * 0.75,
            height: logoDimensions['logoCompanySbm']['width'] * 0.75,
        },
        [theme.breakpoints.up('md')]: {
            width: logoDimensions['logoCompanySbm']['width'],
            height: logoDimensions['logoCompanySbm']['width'],
        },
    },
}))

export default useStyles