import React from 'react'

// IMAGES
import LogoTransporta from '../../../images/logos/home_companies_transporta.svg'
import LogoMit from '../../../images/logos/home_companies_mit.svg'
import LogoValid from '../../../images/logos/home_companies_valid.svg'
import LogoSbm from '../../../images/logos/home_companies_sbm.svg'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'

// STYLES
import useStyles from './companiesUseStyles'

const Companies = () => {
    const classes = useStyles()

    const logoList = [
        {
            image: LogoTransporta,
            className: classes['logoCompanyTransporta'],
        },
        {
            image: LogoMit,
            className: classes['logoCompanyMit'],
        },{
            image: LogoValid,
            className: classes['logoCompanyValid'],
        },{
            image: LogoSbm,
            className: classes['logoCompanySbm'],
        },
    ]

    return (
        <div className={classes['root']}>
            <Grid container className={classes['gridContainer']}>
                {
                    logoList.map((item, index) => (
                        <Grid 
                            key={index} 
                            item
                            xs={6} md={6} lg={3}
                        >
                            <img 
                                src={item['image']} 
                                alt='' 
                                className={item['className']}
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
}

export default Companies